<template>
  <div>
    <!-- #region::Searcher and action button -->
    <BasicListFilters
      title="Clientes"
      new-item-button-label="Nuevo cliente"
      searcher-placeholder="Buscar cliente"
      :show-create-model="$ability.can('create', 'Client')"
      @on:filter="handleFilter"
      @on:click="$router.push({ name: 'new-customer' })"
    />
    <!-- #endregion::Searcher and action button -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingCustomers">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>
          <!-- #region::Customers list table -->
          <b-table
            v-if="availableCustomers"
            :items="customers"
            :fields="columns"
            responsive
            class="my-0"
          >
            <!-- #region:Virtual column for index -->
            <template #cell(No)="data">
              {{ indexFrom + data.index }}
            </template>
            <!-- #endregion:Virtual column for index -->

            <template
              v-if="$ability.can('read', 'Client') && !$ability.can('create', 'Client')"
              #cell(Owner)="{ item }"
            >
              <router-link
                v-if="$ability.can('read', 'Client') && $ability.can('create', 'Client')"
                :to="{ name: 'detail-customer', params: { id: item.IdUser } }"
              ><span class="text-nowrap">{{ item.Owner }}</span>
              </router-link>
              <span
                v-else
                class="text-nowrap"
              >{{ item.Owner }}</span>
            </template>

            <template
              v-if="$ability.can('read', 'Client') && !$ability.can('create', 'Client')"
              #cell(OwnerRole)="{ item }"
            >
              {{ item.OwnerRole }}
            </template>

            <template #cell(Name)="{ item }">
              <router-link
                v-if="$ability.can('read', 'Client') && $ability.can('create', 'Client') || CanLoadAdmin()"
                class="text-nowrap"
                :to="{ name: 'detail-customer', params: { id: item.IdUser } }"
              ><span class="text-nowrap">{{ `${item.Name} ${item.LastName}` }}</span>
              </router-link>
              <span
                v-else
                class="text-nowrap"
              >{{ `${item.Name} ${item.LastName}` }}</span>
            </template>

            <template #cell(email)="{ item }">
              {{ item.email }}
            </template>

            <template #cell(Phone)="{ item }">
              {{ item.Phone }}
            </template>

            <template #cell(Address)="{ item }">
              <span class="text-nowrap">
                {{ item.Address | placeholder("Sin especificar") }}
              </span>
            </template>

            <template #cell(quotes)="{ item }">
              <span class="text-nowrap">
                {{ item.quotes | placeholder("Sin especificar") }}
              </span>
            </template>

            <template #cell(orders)="{ item }">
              <span class="text-nowrap">
                {{ item.orders | placeholder("Sin especificar") }}
              </span>
            </template>

            <template #cell(pendingPayment)="{ item }">
              <span class="text-nowrap">
                {{ item.pendingPayment | placeholder("Sin especificar") }}
              </span>
            </template>

            <template #cell(created_at)="{ item }">
              {{ item.created_at }}
            </template>

            <!-- #region::A virtual column for actions -->
            <template
              v-if="$ability.can('create', 'Client')"
              #cell(actions)="data"
            >
              <div class="d-flex justify-content-between">

                <!-- #region::Delete circuit button -->
                <b-button
                  v-b-tooltip.hover.top="'Eliminar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  @click="onDeleteCustomer(data)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
                <!-- #endregion::Delete circuit button -->

                <!-- #region::Edit wholesaler button -->
                <b-button
                  v-b-tooltip.hover.top="'Editar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  :to="{ name: 'update-customer', params: { id: data.item.IdUser }}"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
                <!-- #endregion::Edit wholesaler button -->

              </div>
            </template>
            <!-- #endregion::A virtual column for actions -->

          </b-table>
          <!-- #endregion::Customers list table -->

          <!-- #region::Alert when no customers are available -->
          <b-alert
            v-if="!availableCustomers && !isLoadingCustomers"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron clientes.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no customers are available -->
        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableCustomers"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalCustomers"
    />
    <!-- #endregion end::Pagination & items per list -->
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  VBTooltip,
  VBToggle,
  BSkeleton,
  BCard,
  BCardText,
  BTable,
  BButton,
  BAlert,
} from 'bootstrap-vue'

import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import BasicListFilters from '@/components/tables/BasicListFilters.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCard,
    BTable,
    BAlert,
    BButton,
    BSkeleton,
    BCardText,
    BasicPaginator,
    BasicListFilters,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      lastPage: 0,
      indexFrom: 0,
      totalCustomers: 0,
      columns: [
        // A virtual column that doesn't exist in items
        {
          key: 'No',
          label: 'No.',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: this.CanLoadAdmin() ? 'Name' : 'Owner',
          label: this.CanLoadAdmin() ? 'Nombre' : 'Agregado por',
          thStyle: 'text-transform: none;',
          thClass: this.$ability.can('read', 'Client') && !this.$ability.can('create', 'Client') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Client') && !this.$ability.can('create', 'Client') ? '' : 'd-none',
        },
        {
          key: 'OwnerRole',
          label: 'Rol',
          thStyle: 'text-transform: none;',
          thClass: this.$ability.can('read', 'Client') && !this.$ability.can('create', 'Client') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('read', 'Client') && !this.$ability.can('create', 'Client') ? '' : 'd-none',
        },
        {
          key: this.CanLoadAdmin() ? 'Owner' : 'Name',
          label: this.CanLoadAdmin() ? 'Agregado por' : 'Nombre',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'email',
          label: 'Correo electrónico',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'Phone',
          label: 'Teléfono',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Address',
          label: 'Dirección',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'quotes',
          label: 'No. Cotizaciones',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'orders',
          label: 'No. Órdenes',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'pendingPayment',
          label: 'Órdenes pendientes de pago',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'created_at',
          label: 'Creado',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thStyle: 'text-transform: none;',
          thClass: this.$ability.can('create', 'Client') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('create', 'Client') ? '' : 'd-none',
        },
      ],
      customers: [],
      isLoadingCustomers: false,
    }
  },
  computed: {
    ...mapGetters({
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
    }),
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    availableCustomers() {
      return this.totalCustomers > 0
    },
  },

  /**
  * Hook que se ejecuta cuando se carga el componente
  *
  * @summary Carga la lista de usuarios cliente
  */
  async created() {
    await this.loadCustomersList()
    await this.verifyContenList()
    this.setOriginRouteToCustomerForm('customers-home')
  },
  methods: {
    ...mapActions({
      loadCustomers: 'customers/loadCustomers',
      deleteCustomer: 'customers/deleteCustomer',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
      setOriginRouteToCustomerForm: 'customers/setOriginRouteToCustomerForm',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Atiende el evento filtrado del componente de filtros
    * @param {Object} filter - Objeto con el tipo de filtro y el valor
    */
    async handleFilter(filter) {
      this.filteredSearchTerm = filter.value
      this.filteredCurrentPage = 1

      // eslint-disable-next-line no-unused-expressions
      this.$refs['basic-paginator']?.resetCurrentPage()
      await this.loadCustomersList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadCustomersList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.customers.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableCustomers) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadCustomersList()
        }
      }
    },

    /**
    * Carga de lista de usuarios cliente
    *
    * @summary Atiende la carga de la lista de usuarios cliente y establece
    * los valores de la paginación
    * @exception Muestra un mensaje de error en caso de que la petición
    * al servidor falle
    */
    async loadCustomersList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingCustomers = true

        const response = await this.loadCustomers(this.loadParams())
        this.customers = response.data.data.users.data
        this.totalCustomers = response.data.data.users.total
        this.indexFrom = response.data.data.users.from
        this.lastPage = response.data.data.users.last_page

        this.isLoadingCustomers = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Eliminación de usuario cliente
    *
    * @summary Confirma la eliminación de un usuario cliente y recarga la lista
    * @param {Ojbject} item - Objeto con la información del usuario cliente
    * @exception Muestra un mensaje de error en caso de que la petición al servidor falle
    */
    async onDeleteCustomer({ item }) {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La información del cliente será eliminada pero se conservarán las órdenes y cotizaciones!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        try {
          await this.deleteCustomer(item.IdUser)
          await this.loadCustomersList()
          await this.verifyContenList()

          this.$swal.fire(
            'Eliminado',
            '¡El cliente ha sido eliminado correctamente!',
            'success',
          )
        } catch (error) {
          this.$swal.close()
          this.showToast(
            'Error de validación',
            error.response.data.message,
            'danger',
          )
        }
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.filteredPerPage,
        resource: 'customers',
        searchTerm: this.filteredSearchTerm,
        currentPage: this.filteredCurrentPage,
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          variant,
          icon: 'BellIcon',
        },
      })
    },
    CanLoadAdmin() {
      const role = JSON.parse(localStorage.userData)?.Role
      return role === 'Administrador'
    },
  },
}
</script>
