var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicListFilters',{attrs:{"title":"Clientes","new-item-button-label":"Nuevo cliente","searcher-placeholder":"Buscar cliente","show-create-model":_vm.$ability.can('create', 'Client')},on:{"on:filter":_vm.handleFilter,"on:click":function($event){return _vm.$router.push({ name: 'new-customer' })}}}),(_vm.isLoadingCustomers)?[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"wave","width":"100%","height":"430px"}})]:[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[(_vm.availableCustomers)?_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.customers,"fields":_vm.columns,"responsive":""},scopedSlots:_vm._u([{key:"cell(No)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.indexFrom + data.index)+" ")]}},(_vm.$ability.can('read', 'Client') && !_vm.$ability.can('create', 'Client'))?{key:"cell(Owner)",fn:function(ref){
var item = ref.item;
return [(_vm.$ability.can('read', 'Client') && _vm.$ability.can('create', 'Client'))?_c('router-link',{attrs:{"to":{ name: 'detail-customer', params: { id: item.IdUser } }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Owner))])]):_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Owner))])]}}:null,(_vm.$ability.can('read', 'Client') && !_vm.$ability.can('create', 'Client'))?{key:"cell(OwnerRole)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.OwnerRole)+" ")]}}:null,{key:"cell(Name)",fn:function(ref){
var item = ref.item;
return [(_vm.$ability.can('read', 'Client') && _vm.$ability.can('create', 'Client') || _vm.CanLoadAdmin())?_c('router-link',{staticClass:"text-nowrap",attrs:{"to":{ name: 'detail-customer', params: { id: item.IdUser } }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(((item.Name) + " " + (item.LastName))))])]):_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(((item.Name) + " " + (item.LastName))))])]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"cell(Phone)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Phone)+" ")]}},{key:"cell(Address)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("placeholder")(item.Address,"Sin especificar"))+" ")])]}},{key:"cell(quotes)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("placeholder")(item.quotes,"Sin especificar"))+" ")])]}},{key:"cell(orders)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("placeholder")(item.orders,"Sin especificar"))+" ")])]}},{key:"cell(pendingPayment)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm._f("placeholder")(item.pendingPayment,"Sin especificar"))+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at)+" ")]}},(_vm.$ability.can('create', 'Client'))?{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Eliminar'),expression:"'Eliminar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.onDeleteCustomer(data)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary","to":{ name: 'update-customer', params: { id: data.item.IdUser }}}},[_c('feather-icon',{attrs:{"icon":"Edit3Icon"}})],1)],1)]}}:null],null,true)}):_vm._e(),(!_vm.availableCustomers && !_vm.isLoadingCustomers)?_c('b-alert',{staticClass:"my-0",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron clientes.")])])]):_vm._e()],1)],1)],(_vm.availableCustomers)?_c('BasicPaginator',{ref:"basic-paginator",staticClass:"mt-2",attrs:{"callback":_vm.handleChangePagination,"total-rows":_vm.totalCustomers}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }